import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoaderPage } from '../../components/LoaderPage/LoaderPage';
import { useEffect } from 'react';
import { isSafeRedirectUrl } from '../../utils/isSafeRedirectUrl';
import { object, string, ZodError, nativeEnum } from 'zod';
import { getConfig } from '../../config/getConfig';

const zod = { object, string, nativeEnum, ZodError };

export type IProviderConfig = typeof PROVIDER_CONFIG;

const { extThirdPartySuccessRedirectUri } = getConfig();

// Add new providers here
export enum ProviderType {
  Jira = 'jira',
}

const PROVIDER_CONFIG = {
  jira: {
    type: ProviderType.Jira,
    host: 'https://auth.atlassian.com',
    path: '/authorize',
    clientId: getConfig().jiraAppId,
    scope: ['read:me', 'read:jira-work', 'offline_access'],
  },
} as const satisfies { [key in ProviderType]: unknown };

const authParamsSchema = zod.object({
  redirectUri: zod.string({ message: 'Invalid redirect uri' }).url('Invalid redirect url'),
  providerId: zod.nativeEnum(ProviderType, { message: 'Invalid provider id' }),
});

function useAuthParams() {
  try {
    const [searchParams] = useSearchParams();
    return authParamsSchema.parse({
      redirectUri: searchParams.get('redirect_uri') ?? '',
      providerId: searchParams.get('provider_id') ?? '',
    });
  } catch (error) {
    if (!(error instanceof zod.ZodError)) throw error;
    throw Error(error.errors.map((e) => e.message).join(', '));
  }
}

export const ThirdPartySignIn = () => {
  const { redirectUri, providerId } = useAuthParams();
  const navigate = useNavigate();

  const providerConfig = PROVIDER_CONFIG[providerId];

  useEffect(() => {
    if (!isSafeRedirectUrl(redirectUri)) {
      navigate(`/unsafe-redirect-url?unsafeUrl=${redirectUri}`);
      return;
    }

    let authorizeUrl: URL;

    switch (providerConfig.type) {
      case ProviderType.Jira:
        authorizeUrl = getJiraAuthUrl(redirectUri);
        break;
      default:
        throw new Error(`Unknown provider ${providerId}`);
    }

    window.location.replace(authorizeUrl.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderPage />;
};

function getJiraAuthUrl(redirectUri: string) {
  const providerConfig = PROVIDER_CONFIG.jira;

  const authorizeUrl = new URL(providerConfig.path, providerConfig.host);
  const scopeString = Array.isArray(providerConfig.scope)
    ? providerConfig.scope.join(' ')
    : String(providerConfig.scope);

  const state = JSON.stringify({
    redirectUri: redirectUri,
    providerType: providerConfig.type,
  });
  authorizeUrl.searchParams.append('state', state);
  authorizeUrl.searchParams.append('scope', scopeString);
  authorizeUrl.searchParams.append('redirect_uri', extThirdPartySuccessRedirectUri);
  authorizeUrl.searchParams.append('client_id', providerConfig.clientId);
  authorizeUrl.searchParams.append('audience', 'api.atlassian.com');
  authorizeUrl.searchParams.append('response_type', 'code');
  authorizeUrl.searchParams.append('prompt', 'consent');

  return authorizeUrl;
}
