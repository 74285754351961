import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Title } from '../../components/common/Title';
import { Text } from '../../components/common/Text';
import { PageContainer } from '../../components/common/Layout';
import { Button } from '../../components/common/Button';
import Header from '../../components/PortalLayout/Header';
import { ProviderType } from './ThirdPartySignIn';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  height: 500px;

  padding: 64px;
  background-color: #fff;
  border-radius: 16px;
`;

const PROVIDER_NAMES = {
  [ProviderType.Jira]: 'Jira',
} satisfies Record<ProviderType, string>;

export const ThirdPartyAfterSignInMessage = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const providerType = search.get('providerType') || '';
  const providerName = PROVIDER_NAMES[providerType as ProviderType] || '';

  return (
    <PageContainer>
      <Header />
      <Wrapper>
        {!providerName && <Title>Something went wrong while connecting your extension.</Title>}
        {providerName && (
          <>
            <Title data-test-id="after-sign-in-message-title">
              {providerName} is now connected to Zencoder!
            </Title>
            <Text>Now you can close this page and return to your IDE.</Text>
          </>
        )}
        <Button $widthType="auto" $variant="secondary" onClick={() => navigate('/dashboard')}>
          Open Admin Panel
        </Button>
      </Wrapper>
    </PageContainer>
  );
};
